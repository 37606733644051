import { 
  AppstoreOutlined, 
  BankOutlined,
  DashboardOutlined,
  TeamOutlined,  
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]

const appsNavFuncionalidades = [{
  key: 'apps-sol',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'solicitacao',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [    
    {
      key: 'apps-solicitacao-solicitacaoList',
      path: `${APP_PREFIX_PATH}/apps/solicitacao/solicitacao-list`,
      title: 'pedidos',
      icon: BankOutlined,
      breadcrumb: true,
      submenu: []
    },
  ]
}]

const appsNavTree = [{
  key: 'apps-adm',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'administracao',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [    
    {
      key: 'apps-usuario-productList',
      path: `${APP_PREFIX_PATH}/apps/usuario/usuario-list`,
      title: 'usuarios',
      icon: TeamOutlined,
      breadcrumb: true,
      submenu: []
    },
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavFuncionalidades,
  ...appsNavTree,
]

export default navigationConfig;
