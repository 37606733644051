import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import firebaseConfig from 'configs/FirebaseConfig';

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
} else {
	firebase.app(); // if already initialized, use that one
}

// firebase utils
const auth = firebase.auth();
const banco = firebase.database().ref();
const storage = firebase.storage();

const currentUser = auth.currentUser;

export {	
	auth,
	banco,
	storage,
	currentUser,
};